import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueRecaptcha from "vue3-recaptcha-v2";
import vue3EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
import VueSocialChat from 'vue-social-chat';
import 'vue-social-chat/dist/style.css';
import axios from 'axios';
//axios.defaults.baseURL='http://127.0.0.1:8000';
axios.defaults.baseURL='https://server.madec.com.mx';
window.axios=require('axios');
window.axios.defaults.withCredentials=true;
//axios.defaults.headers.common['Authorization']='Bearer '+localStorage.getItem('user_token')?localStorage.getItem('user_token'):'';

loadFonts()

const app = createApp(App)
  .use(VueSocialChat)
  .use(vue3EasyDataTable)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueRecaptcha, {
    //siteKey: "6LcZQmsjAAAAAGVszEQlZXz8cQtFpVFmFvnB_-7M",
    //alterDomain: "demo.madec.com.mx", // default: false
    siteKey: "6LdxlkcjAAAAAHo2xTS16JXLaoBjHMgn1PAJMQnn",
    alterDomain: "localhost", // default: false
  });
  //app.config.globalProperties.url="http://127.0.0.1:8000/";
  app.config.globalProperties.url="https://server.madec.com.mx/";
  app.mount('#app');
  