export default {
  //Se exporta un objeto como tal

  name: "main-layout-app",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("@/modules/mainapp/layouts/MainLayoutApp.vue"),
  children: [
    {
      path: "",
      name: "index",
      component: () =>
        import(
          /* webpackChunkName: "index" */ "@/modules/mainapp/views/Index.vue"
        ),
    },
    {
      path: "/contact",
      name: "contact",
      component: () =>
        import(
          /* webpackChunkName: "Contacto" */ "@/modules/mainapp/views/Contact.vue"
        ),
    },
    {
      path: "/aboutus",
      name: "aboutus",
      component: () => import("@/modules/mainapp/views/Nosotros.vue"),
    },
    {
      path: "/categories",
      name: "categories1",
      component: () => import("@/modules/mainapp/views/Categories.vue"),
    },
    {
      path: "/persianas",
      name: "persianas",
      component: () =>
        import("@/modules/mainapp/views/Categorias/Persianas.vue"),
    },
    {
      path: "/cortineria",
      name: "cortineria",
      component: () =>
        import("@/modules/mainapp/views/Categorias/Cortineria.vue"),
    },
    {
      path: "/pisos",
      name: "pisos",
      component: () =>
        import(
          /* webpackChunkName: "index" */ "@/modules/mainapp/views/Categorias/Pisos.vue"
        ),
    },
    {
      path: "/pastos",
      name: "pastos",
      component: () =>
        import(
          /* webpackChunkName: "index" */ "@/modules/mainapp/views/Categorias/Pastos.vue"
        ),
    },
    {
      path: "/alfombras",
      name: "alfombras",
      component: () =>
        import("@/modules/mainapp/views/Categorias/Alfombras.vue"),
    },
    {
      path: "/tapiz",
      name: "tapiz",
      component: () =>
        import("@/modules/mainapp/views/Categorias/TapizParaMuro.vue"),
    },
    {
      path: "/pelicula",
      name: "Pelicula",
      component: () =>
        import("@/modules/mainapp/views/Categorias/Pelicula.vue"),
    },
    {
      path: "/toldos",
      name: "Toldos",
      component: () => import("@/modules/mainapp/views/Categorias/Toldos.vue"),
    },
    {
      path: "/puertas",
      name: "puertas",
      component: () => import("@/modules/mainapp/views/Categorias/Puertas.vue"),
    },
    {
      path: "/panel",
      name: "panel",
      component: () =>
        import("@/modules/mainapp/views/Categorias/PanelDecorativo.vue"),
    },
    {
      path: "/muro",
      name: "muro",
      component: () =>
        import("@/modules/mainapp/views/Categorias/MuroVerde.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/modules/mainapp/views/Register.vue"),
    },
    {
      path: "/view/cortineria",
      name: "cortineriaV",
      component: () =>
        import("@/modules/mainapp/views/CategoriasV/CortineriaV.vue"),
    },
    {
      path: "/view/pisos",
      name: "pisosv",
      component: () => import("@/modules/mainapp/views/CategoriasV/PisosV.vue"),
    },
    {
      path: "/product/:cate/:slug",
      name: "product",
      component: () => import("@/modules/mainapp/views/Product.vue"),
    },
    {
      path: "/view/pastos",
      name: "pastosv",
      component: () =>
        import("@/modules/mainapp/views/CategoriasV/PastosV.vue"),
    },
    {
      path: "/view/toldos",
      name: "toldosv",
      component: () =>
        import("@/modules/mainapp/views/CategoriasV/ToldosV.vue"),
    },
    {
      path: "/view/alfombras",
      name: "alfombrasv",
      component: () =>
        import("@/modules/mainapp/views/CategoriasV/AlfombrasV.vue"),
    },
    {
      path: "/view/tapiz",
      name: "tapizv",
      component: () => import("@/modules/mainapp/views/CategoriasV/TapizV.vue"),
    },
    {
      path: "/view/pelicula",
      name: "peliculav",
      component: () =>
        import("@/modules/mainapp/views/CategoriasV/PeliculaV.vue"),
    },
    {
      path: "/view/puertas",
      name: "puertasv",
      component: () =>
        import("@/modules/mainapp/views/CategoriasV/PuertasV.vue"),
    },
    {
      path: "/view/panel",
      name: "panelv",
      component: () => import("@/modules/mainapp/views/CategoriasV/PanelV.vue"),
    },
    {
      path: "/view/muro",
      name: "murov",
      component: () => import("@/modules/mainapp/views/CategoriasV/MuroV.vue"),
    },
    {
      path: "/searchproduct/:search",
      name: "searchproduct",
      component: () => import("@/modules/mainapp/views/searchproduct.vue"),
    },
    {
      path: "/avisos-de-privacidad",
      name: "avisoPrivacidad",
      component: () =>
        import("@/modules/mainapp/views/Politicas/AvisoPrivacidad.vue"),
    },
    {
      path: "/politicas-de-cambios",
      name: "politicacambios",
      component: () =>
        import("@/modules/mainapp/views/Politicas/PoliticaCambios.vue"),
    },
    {
      path: "/politicas-para-devolver-persianas-enrollables",
      name: "politicapersiana",
      component: () =>
        import("@/modules/mainapp/views/Politicas/PoliticaPersiana.vue"),
    },
    {
      path: "/terminos-y-condiciones",
      name: "terminosycondi",
      component: () =>
        import("@/modules/mainapp/views/Politicas/TerminosCondici.vue"),
    },
  ],
};
