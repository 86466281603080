<template>
  <router-view/>
</template>

<script>

import { defineAsyncComponent } from 'vue'
export default {
  name: 'App',

  data: () => ({
    url:'http://127.0.0.1:8000/',
    //url:'https://server.madec.com.mx/',
  }),

  components: {
      FooterApp: defineAsyncComponent(() => import('@/components/FooterApp.vue')), //Cargas pequeñas en cache importando de esta manera
      AppbarApp: defineAsyncComponent(() => import('@/components/AppbarApp.vue')), //Cargas pequeñas en cache importando de esta manera
      NavigationApp: defineAsyncComponent(()=>import('@/components/NavigationApp.vue')),//Cargas pequeñas en cache importando de esta manera
},
}
</script>
<style>
/* Este font family viene configurado del archivo webfontloader.js. Si se require cambiar la font, ir a Google Fonts y usar lo seleccionado en link */
  * {
      font-family: 'Nunito Sans', sans-serif;
  }
  


</style>
